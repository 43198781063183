let items = [
  {
    title: 'who_we_are',
    items: [
      {
        item: 'about_us',
        pageNameToLinkTo: '/about-us'
      },
      {
        item: 'company_culture',
        pageNameToLinkTo: '/company-culture'
      }
    ]
  },
  {
    title: 'how_we_work',
    items: [
      {
        item: 'affiliate',
        pageNameToLinkTo: '/affiliate'
      },
      {
        item: 'pricing',
        pageNameToLinkTo: '/pricing'
      },
      {
        item: 'technology',
        pageNameToLinkTo: '/technology'
      }
    ]
  },
  {
    title: 'services',
    items: [
      {
        item: 'product_development',
        pageNameToLinkTo: '/product-development'
      },
      {
        item: 'development_squads',
        pageNameToLinkTo: '/development-squads'
      },
      {
        item: 'consultancy',
        pageNameToLinkTo: '/consulting'
      },
      {
        item: 'design',
        pageNameToLinkTo: '/design'
      }
    ]
  },
  {
    title: 'contact',
    items: [
      {
        item: 'write_to_us',
        pageNameToLinkTo: '/contact'
      },
      {
        icons: [
          {
            name: 'FootIcon',
            css: 'mb-1 d-none d-lg-inline',
            position: 'right'
          },
          {
            name: 'FootIcon',
            css: 'mt-1 d-none d-lg-inline',
            position: 'right'
          }
        ],
        item: 'career',
        pageNameToLinkTo: '/career'
      }
    ]
  },
  {
    title: 'social',
    items: [
      {
        icons: [
          {
            name: 'InstagramIcon',
            css: 'mr-1 d-none d-lg-inline',
            position: 'left'
          }
        ],
        css: 'mb-1',
        item: 'item1',
        pageNameToLinkTo: 'https://www.instagram.com/indentstudio/'
      },
      {
        icons: [
          {
            name: 'FacebookIcon',
            css: 'mr-1  d-none d-lg-inline',
            position: 'left'
          }
        ],
        css: 'my-1',
        item: 'item2',
        pageNameToLinkTo: 'https://www.facebook.com/indentstudio.io/'
      },
      {
        icons: [
          {
            name: 'LinkedInIcon',
            css: 'mr-1 d-none d-lg-inline',
            position: 'left'
          }
        ],
        css: 'my-1',
        item: 'item3',
        pageNameToLinkTo: 'https://hu.linkedin.com/company/indent-studio-io/'
      },
      {
        icons: [
          {
            name: 'GitHubIcon',
            css: 'mr-1  d-none d-lg-inline',
            position: 'left'
          }
        ],
        css: 'mt-1',
        item: 'item4',
        pageNameToLinkTo: 'https://github.com/indentstudio/'
      }
    ]
  },
  {
    title: 'other',
    items: [
      {
        icons: [
          {
            name: 'HeartIcon',
            css: 'ml-4 d-none d-lg-inline',
            position: 'right'
          }
        ],
        item: 'csr',
        pageNameToLinkTo: '/csr'
      },
      {
        item: 'privacy',
        pageNameToLinkTo: '/privacy-policy'
      }
    ]
  }
];

export default items;
