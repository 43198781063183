exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-references-jsx": () => import("./../../../src/pages/references.jsx" /* webpackChunkName: "component---src-pages-references-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-position-page-js": () => import("./../../../src/templates/positionPage.js" /* webpackChunkName: "component---src-templates-position-page-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-team-member-page-js": () => import("./../../../src/templates/teamMemberPage.js" /* webpackChunkName: "component---src-templates-team-member-page-js" */)
}

