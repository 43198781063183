import React from 'react';
import Helmet from 'react-helmet';

const Seo = ({ description, lang, keywords, title, image, url, structuredData }) => {
  const seo = {
    description:
      description ||
      'We help startups and companies to rapidly deliver new products and features, save time and provide innovative solutions.',
    lang: lang,
    keywords: keywords || 'development, fejlesztés, programozás, budapest, MVP, csapat',
    title: title || 'Indent Studio',
    image: image || 'https://indentstudio.io/indent_studio.jpg',
    url: url || 'https://indentstudio.io',
    structuredData: structuredData || ''
  };

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: seo.description //
        },
        {
          property: `og:title`,
          content: seo.title
        },
        {
          property: `og:description`,
          content: seo.description
        },
        {
          property: `image`,
          content: seo.image
        },
        {
          property: `og:url`,
          content: seo.url
        },
        {
          property: `og:image`,
          content: seo.image
        },
        {
          property: `og:image:type`,
          content: 'image/jpeg'
        },
        {
          property: `og:image:width`,
          content: '1200'
        },
        {
          property: `og:image:height`,
          content: '627'
        },
        {
          property: `og:image:secure_url`,
          content: seo.image
        },
        {
          property: `og:image:alt`,
          content: seo.description
        },
        {
          property: `og:image:alt`,
          content: seo.description
        },
        {
          property: `og:type`,
          content: `website`
        },

        {
          name: `twitter:url`,
          content: seo.url
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: description
        },
        {
          name: `twitter:url`,
          content: seo.image
        }
      ].concat({
        name: `keywords`,
        content: keywords
      })}
    >
      {lang === 'hu' && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "@id": "https://indentstudio.io",
              "name": "Indent Studio",
              "legalName": "Indent Studio Kft.",
              "description": "Induló és már működő cégeknek segítünk új termékek és  szolgáltatások fejlesztésében, időt spórolva, innovatív és modern eszközökkel.",
              "url": "https://indentstudio.io",
              "logo": "https://indentstudio.io/images/favicon/favicon.png",
              "sameAs": [
                "https://www.facebook.com/indentstudio.io/",
                "https://www.instagram.com/indentstudio/",
                "https://www.linkedin.com/company/indent-studio-io/",
                "https://github.com/indentstudio"
              ]
            }
          `}
        </script>
      )}
      {lang === 'en' && (
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            "@id": "https://indentstudio.io",
            "name": "Indent Studio",
            "legalName": "Indent Studio Kft.",
            "description": "We help startups and companies to rapidly deliver new products and features, save time and provide innovative solutions.",
            "url": "https://indentstudio.io",
            "logo": "https://indentstudio.io/images/favicon/favicon.png",
            "sameAs": [
              "https://www.facebook.com/indentstudio.io/",
              "https://www.instagram.com/indentstudio/",
              "https://www.linkedin.com/company/indent-studio-io/",
              "https://github.com/indentstudio"
            ]
          }
        `}
        </script>
      )}
    </Helmet>
  );
};

export default Seo;
