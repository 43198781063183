import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col, Form, Button, Nav } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useForm } from 'react-hook-form';
import { saveDocument } from './firebase';
import * as styles from './contact.form.module.scss';

const options = [
  { selected: false, action: 'main.contact.checkbox1' },
  { selected: false, action: 'main.contact.checkbox2' },
  { selected: false, action: 'main.contact.checkbox3' },
  { selected: false, action: 'main.contact.checkbox4' },
  { selected: false, action: 'main.contact.checkbox5' }
];

// Emails are sent by a plugin that tracks the records in the Firestore `emails` collection
// and gets triggered when a new record is added to the collection.
async function sendEmail(data) {
  await saveDocument('emails', data);
}

const ContactFormFields = () => {
  const [selectedOption, setSelectedOption] = useState(options);
  const [emailSent, setEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm();

  const intl = useIntl();

  const optionChange = (e, index) => {
    setSelectedOption(
      selectedOption.map((option, i) => {
        if (i === index) {
          return { ...option, selected: e.target.checked };
        } else {
          return option;
        }
      })
    );
  };

  const onSubmit = async (data) => {
    const arr = selectedOption
      .filter((o) => o.selected)
      .map((o) => intl.formatMessage({ id: o.action }));

    data.subject = arr;

    await sendEmail(data);
    setEmailSent(true);
  };

  return emailSent ? (
    <>
      <h2 className="text-center pt-4">{intl.formatMessage({ id: 'main.contact.thanks' })}</h2>
      <p className="text-center pb-4"> {intl.formatMessage({ id: 'main.contact.soon' })}</p>
    </>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container className="px-0">
        <Form.Group as={Row}>
          <Form.Label column sm="2" className={styles.formLabel}>
            {intl.formatMessage({ id: 'main.contact.label1' })}
          </Form.Label>
          <Col lg="10">
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'main.contact.placeholder1' })}
              {...register('clientName', { maxLength: 30 })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column className={styles.formLabel}>
            {intl.formatMessage({ id: 'main.contact.label2' })}
          </Form.Label>
          <Container>
            {selectedOption.map((checkbox, index) => (
              <ButtonGroup key={index}>
                <ToggleButton
                  className={styles.toggleButton}
                  id={`toggle-check-${index}`}
                  type="checkbox"
                  variant="secondary"
                  onChange={(e) => optionChange(e, index)}
                  checked={checkbox.selected}
                  value={intl.formatMessage({ id: checkbox.action })}
                  style={{
                    backgroundColor: !checkbox.selected ? '#fafafa' : '#2c2d43',
                    color: !checkbox.selected ? '#2c2d43' : '#ffffff'
                  }}
                >
                  {intl.formatMessage({ id: checkbox.action })}
                </ToggleButton>
              </ButtonGroup>
            ))}
          </Container>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="12" className={styles.formLabel}>
            {intl.formatMessage({ id: 'main.contact.label3' })}
          </Form.Label>
          <Col sm="12">
            <Form.Control
              as="textarea"
              rows={5}
              placeholder={intl.formatMessage({ id: 'main.contact.placeholder3' })}
              {...register('details', { maxLength: 500 })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" className={styles.formLabel}>
            {intl.formatMessage({ id: 'main.contact.label4' })}
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="tel"
              required={!dirtyFields['email']}
              placeholder={intl.formatMessage({ id: 'main.contact.placeholder4_1' })}
              {...register('phone', {
                maxLength: 20,
                minLength: 3,
                required: !dirtyFields['email']
              })}
            />
          </Col>
          <Form.Label column sm="1" className={styles.formLabel}>
            {intl.formatMessage({ id: 'main.contact.separator' })}
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="email"
              required={!dirtyFields['phone']}
              placeholder={intl.formatMessage({ id: 'main.contact.placeholder4_2' })}
              {...register('email', {
                maxLength: 30,
                required: !dirtyFields['phone']
              })}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Button type="submit" className={styles.submitButton}>
              {intl.formatMessage({ id: 'main.contact.submit_button' })}
            </Button>
            <p className="my-2">
              {intl.formatMessage({ id: 'main.contact.info' })}

              <Nav.Link
                as={'a'}
                href={'tel:' + intl.formatMessage({ id: 'main.contact.phone' })}
                className={styles.highlight}
              >
                {intl.formatMessage({ id: 'main.contact.phone' })}
              </Nav.Link>
              {intl.formatMessage({ id: 'main.contact.separator2' })}

              <Nav.Link
                as={'a'}
                href={'mailto:' + intl.formatMessage({ id: 'main.contact.email' })}
                className={styles.highlight}
              >
                {intl.formatMessage({ id: 'main.contact.email' })}
              </Nav.Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const ContactForm = () => {
  const intl = useIntl();

  return (
    <Container id="contact" fluid className={styles.contactForm}>
      <Container>
        <Row>
          <Col className="text-center">
            <h2> {intl.formatMessage({ id: 'main.contact.title' })}</h2>
            <hr className="rectangle" />
            <p>{intl.formatMessage({ id: 'main.contact.subtitle' })}</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }} className="px-0">
            <ContactFormFields />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactForm;
