import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import { Fade } from 'react-awesome-reveal';
import { useIntl } from 'gatsby-plugin-intl';

import TechologyWithDevIcon from './TechnologyWithDevIcon';

import * as styles from './technologies.module.scss';
import TechnologyWithImage from './TechnologyWithImage';

const Technologies = () => {
  const intl = useIntl();

  return (
    <Container className="mb-4 pb-4">
      <Row className="my-4">
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">{intl.formatMessage({ id: `technologies.title` })}</h2>
          <hr className="rectangle"></hr>
        </Col>
      </Row>
      <Row className={c(styles.technologies, 'mx-4')}>
        <Col>
          <h3>{intl.formatMessage({ id: `technologies.general` })}</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechologyWithDevIcon iconName="google-plain" technologyName="Gsuite" />
            <TechnologyWithImage imageName={'trello'} technologyName="Trello" />
            <TechnologyWithImage imageName={'discord'} technologyName="Discord" />
            <TechologyWithDevIcon iconName="github-original" technologyName="Github" />
            <TechologyWithDevIcon iconName="vscode-plain" technologyName="VS Code" />
          </Fade>
        </Col>
        <Col>
          <h3>Frontend</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechologyWithDevIcon iconName="react-original" technologyName="React" />
            <TechologyWithDevIcon iconName="angularjs-plain" technologyName="Angular" />
            <TechologyWithDevIcon iconName="gatsby-plain" technologyName="Gatsby" />
            <TechologyWithDevIcon iconName="nextjs-original" technologyName="Next.js" />
            <TechologyWithDevIcon iconName="eslint-original" technologyName="Lint tools" />
          </Fade>
        </Col>
        <Col>
          <h3>{intl.formatMessage({ id: `technologies.mobile` })}</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechologyWithDevIcon iconName="flutter-plain" technologyName="Flutter" />
            <TechologyWithDevIcon iconName="react-original" technologyName="React Native" />
            <TechnologyWithImage imageName={'expo'} technologyName="Expo" />
          </Fade>
        </Col>
        <Col>
          <h3>Backend</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechologyWithDevIcon iconName="laravel-plain" technologyName="Laravel" />
            <TechologyWithDevIcon iconName="nodejs-plain" technologyName="Node.js" />
            <TechologyWithDevIcon iconName="nestjs-plain" technologyName="Nest.js" />
            <TechologyWithDevIcon
              iconName="amazonwebservices-original"
              technologyName="AWS Lambda"
            />
            <TechologyWithDevIcon iconName="gatsby-plain" technologyName="Gatsby" />
          </Fade>
        </Col>
        <Col>
          <h3>DevOps</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechologyWithDevIcon
              iconName="amazonwebservices-original"
              technologyName="Lightsail, EC2"
            />
            <TechologyWithDevIcon iconName="docker-plain" technologyName="Docker" />
            <TechnologyWithImage imageName={'forge'} technologyName="Forge" />
            <TechnologyWithImage imageName={'netlify'} technologyName="Netlify" />
          </Fade>
        </Col>
        <Col>
          <h3>{intl.formatMessage({ id: `technologies.ai_tools` })}</h3>
          <Fade triggerOnce cascade damping={0.3}>
            <TechnologyWithImage imageName={'copilot'} technologyName="GitHub Copilot" />
            <TechnologyWithImage imageName={'uizard'} technologyName="uizard" />
            <TechnologyWithImage imageName={'openai'} technologyName="OpenAI" />
            <TechnologyWithImage imageName={'copyai'} technologyName="copy.ai" />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

export default Technologies;
