import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import ArrowIcon from '../../../images/icons/arrow.inline.svg';

import * as styles from './position_card.module.scss';

const PositionCard = ({ ...props }) => {
  const { position } = props;
  const intl = useIntl();

  return (
    <Container className={styles.positionCard}>
      <Row className="justify-content-between">
        <Col className="text-left">
          <h6>{position.title}</h6>
          <p>
            {position.contractType} - <span>{position.location}</span>
          </p>
          <Link to={`/${position.path}`} className={styles.button}>
            {intl.formatMessage({ id: 'positions.button' })} <ArrowIcon />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default PositionCard;
