// extracted by mini-css-extract-plugin
export var bgLightPurple = "allcomponents-module--bgLightPurple--510b1";
export var blue = "allcomponents-module--blue--d42c3";
export var blueArea = "allcomponents-module--blueArea--dfa4a";
export var circle = "allcomponents-module--circle--44a2e";
export var col1 = "allcomponents-module--col1--4f47d";
export var col2 = "allcomponents-module--col2--ef69c";
export var container = "allcomponents-module--container--4af9b";
export var development = "allcomponents-module--development--c817d";
export var grey = "allcomponents-module--grey--4e5b6";
export var greyArea = "allcomponents-module--greyArea--285c5";
export var heart = "allcomponents-module--heart--47a4f";
export var staticContainer = "allcomponents-module--staticContainer--10e88";
export var svgContainer = "allcomponents-module--svgContainer--97a4c";
export var textArea = "allcomponents-module--textArea--5625e";