import React from 'react';
import c from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';
import Typed from 'react-typed';
import { Container, Row, Col } from 'react-bootstrap';
import { Slide } from 'react-awesome-reveal';
import { StaticImage } from 'gatsby-plugin-image';
// import Lottie from 'lottie-react';

import * as styles from './hero.module.scss';
// import animationData from '../../lottie/landing.json';

const Hero = ({ ...props }) => {
  const { typedTextArray, title, subtitle, hashtags } = props;
  const intl = useIntl();

  return (
    <div className={c(styles.heroSection)}>
      <StaticImage
        src={'../../images/hero/arrow.png'}
        className={c(styles.arrow, 'd-none d-md-block')}
        placeholder="blurred"
        alt="Arrow"
      />
      <Container fluid>
        <Row className="pt-4">
          <Col md={{ span: 6, offset: 3 }} className={styles.heroHeading}>
            <Slide triggerOnce>
              <h1 className={c(styles.heroTitle)}>{title}</h1>
            </Slide>
            {subtitle && (
              <Slide triggerOnce delay={100}>
                <h2 className={c(styles.heroSubTitle)}>
                  {subtitle}{' '}
                  {typedTextArray && (
                    <>
                      <Typed
                        className={styles.typedText}
                        strings={typedTextArray}
                        cursorChar={'_'}
                        typeSpeed={35}
                        backSpeed={40}
                        loop
                      />
                      &nbsp;
                      {intl.formatMessage({ id: `main.hero.project` })}
                    </>
                  )}
                </h2>
              </Slide>
            )}
            {hashtags && (
              <Slide triggerOnce delay={150}>
                <p className={styles.hashtags}>
                  {hashtags.map((hashtag, index) => (
                    <span key={index}>{hashtag} </span>
                  ))}
                </p>
              </Slide>
            )}
          </Col>
          {/* <Col md={3}>
            <Lottie animationData={animationData} loop={true} />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
