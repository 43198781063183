import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'gatsby-plugin-intl';
import c from 'classnames';

import * as styles from '../services.module.scss';

const ServicesItem = ({ ...props }) => {
  const { serviceIcon, title, paragraph, link, linkText, color } = props;

  return (
    <Link to={`/${link}`}>
      <Container
        style={{ backgroundColor: color }}
        className={c(styles.item, 'mb-md-2 mb-lg-0 mx-md-0')}
      >
        <Row>
          <Col className={c(styles.imageContainer)}>
            <img
              className={c(styles.svgIcon, 'py-2')}
              src={serviceIcon.localFile.publicURL}
              alt={title}
              description={paragraph}
              title={title}
              height={156}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3 className={c(styles.paragraphTitle, 'pl-md-0 pl-2')}>{title}</h3>
            <p className={c(styles.paragraph, 'pr-md-2 pl-md-0 pr-4 pl-2')}>
              {paragraph}
              <br />
              <span className={c('mt-1 d-block', styles.linkText)}>
                {linkText} {'>>'}
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Link>
  );
};

export default ServicesItem;
