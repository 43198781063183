import React from 'react';

import * as styles from '../../footer.module.scss';
import GitHubIcon from '../../../../images/icons/github.inline.svg';
import LinkedInIcon from '../../../../images/icons/linkedin.inline.svg';
import InstagramIcon from '../../../../images/icons/instagram.inline.svg';
import FacebookIcon from '../../../../images/icons/facebook.inline.svg';

const FooterItemIcon = ({ ...props }) => {
  const { iconDetail } = props;

  const icons = {
    FacebookIcon: <FacebookIcon className={styles.socialIcon} />,
    InstagramIcon: <InstagramIcon className={styles.socialIcon} />,
    LinkedInIcon: <LinkedInIcon className={styles.socialIcon} />,
    GitHubIcon: <GitHubIcon className={styles.socialIcon} />
  };

  return <> {icons[iconDetail.name]}</>;
};

export default FooterItemIcon;
