import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from './team_member.module.scss';

const TeamMember = ({ ...props }) => {
  const { image, firstName, lastName, position, member } = props;
  const intl = useIntl();
  const [hover, setHover] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  const name = () => {
    if (lastName && firstName) {
      return intl.locale === 'hu' ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
    } else {
      return firstName ? `${firstName}` : `${lastName}`;
    }
  };

  useEffect(() => {
    member.content.map((contentBlock) =>
      contentBlock.markdown.data.childMarkdownRemark.html !== ''
        ? setHasContent(true)
        : setHasContent(false)
    );
  }, [hover, member]);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.teamMember}
      style={{
        cursor: hover && hasContent ? 'pointer' : null,
        transform: hover && hasContent ? 'scale(1.05)' : null
      }}
    >
      <GatsbyImage
        image={getImage(image.localFile)}
        tag="div"
        alt={name()}
        description={name()}
        title={name()}
      />
      <Container>
        <Row className="justify-content-between">
          <Col>
            <h5 className="pt-1 mb-0">{name()}</h5>
            <p className="pl-0 mt-0">{position}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamMember;
