import React from 'react';
import c from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';
import FooterItemIcon from './FooterItemIcon';
import FooterLink from './FooterLink';

import * as styles from '../footer.module.scss';

const FooterItem = ({ ...props }) => {
  const { link, langKey, icons, className } = props;
  const intl = useIntl();

  const beforeIcons = [];

  icons?.forEach((icon) => {
    beforeIcons.push(icon);
  });

  return (
    <>
      <FooterLink className={c(styles.footerItem, 'd-block', className)} to={link}>
        {beforeIcons.map((icon, index) => {
          return <FooterItemIcon iconDetail={icon} key={index} />;
        })}

        {intl.formatMessage({ id: `main.footer.footer_items.${langKey}` })}
      </FooterLink>
    </>
  );
};

export default FooterItem;
