import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import Lottie from 'lottie-react';
import animationData from '../../lottie/about_us.json';

import * as styles from './about.us.module.scss';

const AboutUs = () => {
  const intl = useIntl();

  return (
    <Container fluid className={c(styles.aboutUsContainer, 'py-4 mt-4')}>
      <Row>
        <Col md={5} className="d-flex justify-content-center align-items-center">
          <Lottie style={{ height: 450 }} animationData={animationData} loop={true} />
        </Col>
        <Col md={7} className={c(styles.textArea, 'px-0 py-2 text-center')}>
          <Col md={12}>
            <h2>{intl.formatMessage({ id: 'main.agitation.title' })}</h2>
            <hr className="rectangle" />
          </Col>
          <Col md={12}>
            <p className="lead">{intl.formatMessage({ id: 'main.agitation.paragraph1' })}</p>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Link to="/about-us" className={c('btn btn-primary', styles.btn)}>
                  {intl.formatMessage({ id: 'main.agitation.button' })}
                </Link>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
