import React from 'react';
import c from 'classnames';

import * as styles from '../technologies.module.scss';

const TechologyWithDevIcon = ({ ...props }) => {
  const { iconName, technologyName } = props;

  return (
    <div className={c(styles.devIconContainer, 'd-flex align-items-center py-1')}>
      <i className={c(styles.devIcon, `devicon-${iconName} colored`)} />
      &nbsp;&nbsp;{technologyName}
    </div>
  );
};

export default TechologyWithDevIcon;
