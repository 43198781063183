import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';

import Footer from '../Footer';
import Navbar from '../Navbar';

const Layout = ({ children }) => (
  <div>
    <Navbar />
    <main>{children}</main>
    <Footer />
  </div>
);

export default injectIntl(Layout);
