import * as React from 'react';
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { Container, Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Fade } from 'react-awesome-reveal';

import * as styles from './paginated.articles.module.scss';

const query = graphql`
  {
    allStrapiArticle(sort: { createdAtDate: DESC }) {
      nodes {
        id
        title
        path
        createdAtDate(formatString: "MMMM Do, YYYY")
        locale
        seo {
          metaImage {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 627)
              }
            }
          }
        }
      }
    }
  }
`;

const PaginatedArticles = () => {
  const data = useStaticQuery(query);
  const intl = useIntl();
  const articles = data.allStrapiArticle.nodes.filter((node) => node.locale === intl.locale);

  const [itemOffset, setItemOffset] = useState(0);

  // itemsPerPage = 3
  const endOffset = itemOffset + 3;
  const currentItems = articles.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(articles.length / 3);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 3) % articles.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {articles && (
        <Container className={styles.containerFluid}>
          <Row className="text-center">
            <Col>
              <h2>{intl.formatMessage({ id: 'main.articles.title' })}</h2>
              <hr className="rectangle" />
              <p>{intl.formatMessage({ id: 'main.articles.subtitle' })}</p>
            </Col>
          </Row>
          <Row className="justify-content-around">
            {currentItems.map((article, index) => (
              <Fade triggerOnce delay={index * 60} key={article.id}>
                <Link to={`/${article.path}`} aria-label={`${article.title}`}>
                  <div className={styles.linkBtn}>
                    <div className={styles.bannerPostItem}>
                      <GatsbyImage
                        image={getImage(
                          article.seo.metaImage.localFile.childImageSharp.gatsbyImageData
                        )}
                        alt={article.title}
                        className={styles.postImage}
                      />
                      <div className={styles.postContent}>
                        <h3>{article.title}</h3>
                        <ul className={styles.infoEntry}>
                          <li>{article.createdAtDate}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Link>
              </Fade>
            ))}
          </Row>

          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            containerClassName={styles.paginatorContainer}
            pageClassName={styles.pageTag}
            activeClassName={styles.activePage}
            pageLinkClassName={styles.pageLink}
            previousClassName={styles.previousButton}
            nextClassName={styles.nextButton}
            disabledClassName={styles.disabledButton}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
          />
        </Container>
      )}
    </>
  );
};

export default PaginatedArticles;
