import React, { useEffect } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Hero';
import StaticComponent from '../components/StaticComponents';

import * as styles from '../components/ContactForm/contact.form.module.scss';

const PositionPage = ({ data }) => {
  const intl = useIntl();
  const page = data.allStrapiPosition.nodes.filter((node) => node.locale === intl.locale)[0];

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        keywords={page.seo.keywords}
      />
      <Hero title={page.title} subtitle={page.subtitle} />
      <div className="static-component">
        {page.content &&
          page.content.map(
            (contentBlock, index) =>
              contentBlock.markdown.data !== null && (
                <div key={index}>
                  <StaticComponent
                    content={contentBlock.markdown.data.childMarkdownRemark.html}
                    type={contentBlock.type}
                    image={contentBlock.image}
                    path={page.path}
                  />
                </div>
              )
          )}
      </div>
      <Container fluid>
        <p className="text-center mr-4">
          {intl.formatMessage({ id: 'main.contact.info' })}
          <Nav.Link
            as={'a'}
            href={'tel:' + intl.formatMessage({ id: 'main.contact.phone' })}
            className={styles.highlight}
          >
            {intl.formatMessage({ id: 'main.contact.phone' })}
          </Nav.Link>
          {intl.formatMessage({ id: 'main.contact.separator2' })}

          <Nav.Link
            as={'a'}
            href={'mailto:' + intl.formatMessage({ id: 'main.contact.email' })}
            className={styles.highlight}
          >
            {intl.formatMessage({ id: 'main.contact.email' })}
          </Nav.Link>
        </p>
      </Container>
    </Layout>
  );
};

export const PositionPageQuery = graphql`
  query PositionPage($pagePath: String) {
    allStrapiPosition(filter: { path: { eq: $pagePath } }) {
      nodes {
        path
        title
        subtitle
        locale
        content {
          type
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        seo {
          metaDescription
          metaTitle
          keywords
        }
      }
    }
  }
`;

export default PositionPage;
