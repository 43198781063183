import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Hero';
import Team from '../components/Team';
import Reference from '../components/Reference';
import Services from '../components/Services';
import PaginatedArticles from '../components/PaginatedArticles';
import Technologies from '../components/Technologies';
import AboutUs from '../components/AboutUs';

const IndexPage = () => {
  const intl = useIntl();

  const generalSettings = useStaticQuery(graphql`
    query Site {
      allStrapiGeneralSetting {
        nodes {
          siteDescription
          siteTitle
          locale
          seo {
            metaDescription
            metaTitle
            keywords
          }
          socials {
            socialNetwork
            title
            description
          }
        }
      }
    }
  `);

  const seoData = generalSettings.allStrapiGeneralSetting.nodes.find(
    (s) => s.locale === intl.locale
  );

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={seoData.seo.metaTitle}
        description={seoData.seo.metaDescription}
        keywords={seoData.seo.keywords}
      />
      <Hero
        title={intl.formatMessage({ id: `main.hero.title` })}
        subtitle={intl.formatMessage({ id: `main.hero.subtitle` })}
        typedTextArray={[
          intl.formatMessage({ id: 'main.hero.plan' }),
          intl.formatMessage({ id: 'main.hero.design' }),
          intl.formatMessage({ id: 'main.hero.develop' }),
          intl.formatMessage({ id: 'main.hero.test' }),
          intl.formatMessage({ id: 'main.hero.validate' })
        ]}
        hashtags={[
          intl.formatMessage({ id: 'main.hero.hashtags.consultancy' }),
          intl.formatMessage({ id: 'main.hero.hashtags.strategy' }),
          intl.formatMessage({ id: 'main.hero.hashtags.UX' }),
          intl.formatMessage({ id: 'main.hero.hashtags.UI' }),
          intl.formatMessage({ id: 'main.hero.hashtags.development' })
        ]}
      />
      <AboutUs />
      <Services />
      <Reference />
      <Technologies />
      <PaginatedArticles />
      <Team />
    </Layout>
  );
};

export default IndexPage;
