import React from 'react';
import { Row, Badge, NavLink, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import c from 'classnames';

import * as styles from './../reference.module.scss';

const ReferenceItem = ({ ...props }) => {
  const { mainImage, logo, name, link, description, badges } = props;

  return (
    <Row className="mb-2">
      <Col>
        <div className={styles.item}>
          <div className={styles.image}>
            <GatsbyImage
              image={getImage(mainImage.localFile)}
              alt={name}
              description={`${name}`}
              title={name}
            />
          </div>
          <NavLink target="blank" className="mt-1" href={link}>
            <GatsbyImage
              image={getImage(logo.localFile)}
              alt={name}
              description={`${name} logo`}
              title={name}
            />
          </NavLink>
          <div className={styles.text}>{description}</div>
          <div>
            {badges.map((badge, index) => (
              <Badge
                variant="secondary"
                className={c(styles.keyWords, 'mr-1 px-1 mt-1')}
                key={index}
              >
                {badge.name}
              </Badge>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ReferenceItem;
