import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImageOnRight = ({ ...props }) => {
  const { content, image } = props;

  return (
    <Container className="pt-md-5 pt-3 mb-md-5 mb-2 pb-md-5">
      <Row>
        <Col md={7}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
        <Col className="d-flex justify-content-center">
          {image && <GatsbyImage image={getImage(image)} placeholder="blurred" />}
          {!image && (
            <StaticImage
              src="../../../images/static/randomphone.png"
              alt="phone"
              placeholder="blurred"
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ImageOnRight;
