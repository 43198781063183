import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Circle from '../../../images/static/circle.inline.svg';
import Heart from '../../../images/static/heart.inline.svg';

import * as styles from '../allcomponents.module.scss';

const IconsInBackground = ({ ...props }) => {
  const { content } = props;

  return (
    <Container fluid className="mb-md-5">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Circle className={styles.circle} />
          <Heart className={styles.heart} />
        </Col>
        <Col md={{ span: 6, offset: 3 }} className={styles.svgContainer}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
      </Row>
    </Container>
  );
};

export default IconsInBackground;
