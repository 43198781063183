let firebaseApp;

const initializeFirebase = async () => {
  const firebase = await import('firebase/app');
  await import('firebase/firestore');
  firebaseApp = firebase.initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    projectId: process.env.FIREBASE_PROJECT_ID
  });
};

export async function saveDocument(collection, data) {
  if (!firebaseApp) {
    await initializeFirebase();
  }

  const newDocRef = firebaseApp.firestore().collection(collection).doc();
  await newDocRef.set(data);
}
