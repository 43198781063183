import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import StaticComponent from '../components/StaticComponents';
import { Container, Col, Row } from 'react-bootstrap';

const ArticlePage = ({ data }) => {
  const intl = useIntl();
  const page = data.allStrapiArticle.nodes.filter((node) => node.locale === intl.locale)[0];

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        keywords={page.seo.keywords}
      />
      <Hero title={page.title} subtitle={page.subtitle} />
      <Container fluid>
        <Row>
          <Col md={{ span: 4, offset: 3 }} className="py-3">
            <div className="static-component">
              {page.content &&
                page.content.map(
                  (contentBlock, index) =>
                    contentBlock.markdown.data !== null && (
                      <StaticComponent
                        key={index}
                        content={contentBlock.markdown.data.childMarkdownRemark.html}
                        type={contentBlock.type}
                        image={contentBlock.image}
                      />
                    )
                )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const ArticlePageQuery = graphql`
  query ArticlePage($pagePath: String) {
    allStrapiArticle(filter: { path: { eq: $pagePath } }) {
      nodes {
        id
        path
        title
        subtitle
        locale
        content {
          type
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        seo {
          metaDescription
          metaTitle
          keywords
        }
      }
    }
  }
`;

export default ArticlePage;
