import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import ServicesItem from './ServiceItem';
import { Fade } from 'react-awesome-reveal';

import * as styles from './services.module.scss';

const Services = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query Services {
      allStrapiService {
        nodes {
          name
          description
          link
          color
          order
          locale
          serviceIcon {
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const sortAsc = (a, b) => a.order - b.order;

  const services = data.allStrapiService.nodes
    .filter((node) => node.locale === intl.locale)
    .sort(sortAsc);

  return (
    <Container id="services" className={styles.service}>
      <Row className="text-center">
        <Col>
          <h2>{intl.formatMessage({ id: 'main.servicesWithImage.title' })}</h2>
          <hr className="rectangle" />
          <p>{intl.formatMessage({ id: 'main.servicesWithImage.subtitle' })}</p>
        </Col>
      </Row>
      <Row>
        {services.map((service, index) => (
          <Col xs={12} md={6} lg={3} key={index}>
            <Fade triggerOnce delay={index * 100}>
              <ServicesItem
                color={service.color}
                title={service.name}
                paragraph={service.description}
                serviceIcon={service.serviceIcon}
                link={service.link}
                linkText={intl.formatMessage({
                  id: 'main.services.learn_more'
                })}
              />
            </Fade>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
