import React, { Fragment, useState } from 'react';
import c from 'classnames';
import { Accordion, Card, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';

import FooterItem from '../FooterItem';
import * as styles from '../footer.module.scss';
import ChevronDownIcon from '../../../images/icons/chevron-down.inline.svg';

const FooterColumn = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { title, items } = props;
  const intl = useIntl();

  return (
    <Fragment>
      <Col xl={2} lg={4} className="d-none d-lg-block mb-lg-4 mb-xl-0">
        <h3 className={c(styles.footerTitle, 'text-white ')}>
          {intl.formatMessage({ id: `main.footer.footer_titles.${title}` })}
        </h3>
        {items.map((item, index) => (
          <FooterItem
            link={item.pageNameToLinkTo}
            langKey={`${title}.${item.item}`}
            icons={item.icons}
            key={index}
            className={item.css}
          />
        ))}
      </Col>

      <Col xs={12} className="d-lg-none">
        <Accordion>
          <Card bg="transparent">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={() => setIsOpen(!isOpen)}
              className="pb-0 px-0"
            >
              <h3 className={c(styles.footerTitle, 'text-white mb-0 pb-0')}>
                {intl.formatMessage({ id: `main.footer.footer_titles.${title}` })}
                <span className="float-right">
                  <ChevronDownIcon className={isOpen ? styles.open : styles.close} />
                </span>
              </h3>
            </Accordion.Toggle>
            {items.map((item, index) => (
              <Accordion.Collapse eventKey="0" key={index}>
                <Card.Body className="py-0 px-0">
                  <FooterItem
                    link={item.pageNameToLinkTo}
                    langKey={`${title}.${item.item}`}
                    icons={item.icons}
                  />
                </Card.Body>
              </Accordion.Collapse>
            ))}
          </Card>
        </Accordion>
      </Col>
    </Fragment>
  );
};

export default FooterColumn;
