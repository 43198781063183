import React from 'react';
import { Container, Col } from 'react-bootstrap';
import c from 'classnames';
import TwoColumns from './TwoColumns';
import ImageOnLeft from './ImageOnLeft';
import ImageOnRight from './ImageOnRight';
import GreyBackground from './GreyBackground';
import IconsInBackground from './IconsInBackground';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CuteCat from './CuteCat';

import * as styles from './allcomponents.module.scss';

const StaticComponent = ({ ...props }) => {
  const { content, type, image } = props;

  return (
    <>
      {type === 'article' && <div dangerouslySetInnerHTML={{ __html: content }} />}
      {type === 'simple' && (
        <Container fluid className={c(styles.static, 'py-4')}>
          <Col md={{ span: 6, offset: 3 }}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
        </Container>
      )}
      {type === 'teamMember' && (
        <Container fluid className={c(styles.static, 'py-4')}>
          <Col md={{ span: 6, offset: 3 }}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {image !== null && (
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={image.alternativeText ? image.alternativeText : 'image'}
              />
            )}
          </Col>
        </Container>
      )}
      {type === 'position' && (
        <Container fluid className={c(styles.static, 'py-0')}>
          <div className="text-center mb-4">
            {image !== null && (
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={image.alternativeText ? image.alternativeText : 'image'}
              />
            )}
          </div>
          <Col md={{ span: 6, offset: 3 }}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
        </Container>
      )}
      {type === '2 columns' && <TwoColumns content={content} />}
      {type === 'imageOnLeft' && <ImageOnLeft content={content} image={image} />}
      {type === 'imageOnRight' && image && <ImageOnRight content={content} image={image} />}
      {type === 'greyBackground' && <GreyBackground content={content} />}
      {type === 'iconsInBackground' && <IconsInBackground content={content} />}
      {type === 'cuteCat' && <CuteCat content={content} />}
    </>
  );
};

export default StaticComponent;
