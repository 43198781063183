import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import StaticComponent from '../components/StaticComponents';
import Seo from '../components/Seo';

const TeamMemberPage = ({ data }) => {
  const intl = useIntl();
  const page = data.allStrapiTeamMember.nodes.filter((node) => node.locale === intl.locale)[0];

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={page.subtitle}
        description={page.title}
        keywords={
          intl.locale === 'en' ? 'developer,designer,copywriter' : 'fejlesztő,dizájner,szövegíró'
        }
      />
      <Hero title={page.title} subtitle={page.subtitle} />
      <div className="static-component">
        <div className="text-center">
          <GatsbyImage
            image={getImage(page.image.localFile)}
            tag="div"
            alt={page.image.alternativeText ? page.image.alternativeText : 'image'}
          />
        </div>
        {page.content &&
          page.content.map(
            (contentBlock, index) =>
              contentBlock.markdown.data !== null && (
                <StaticComponent
                  key={index}
                  content={contentBlock.markdown.data.childMarkdownRemark.html}
                  type={contentBlock.type}
                  image={contentBlock.image}
                />
              )
          )}
      </div>
    </Layout>
  );
};

export const TeamMemberPageQuery = graphql`
  query TeamMemberPage($pagePath: String) {
    allStrapiTeamMember(filter: { path: { eq: $pagePath } }) {
      nodes {
        id
        path
        title
        subtitle
        locale
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(height: 700, placeholder: BLURRED)
            }
          }
        }
        content {
          type
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 800, placeholder: BLURRED)
              }
            }
            alternativeText
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default TeamMemberPage;
