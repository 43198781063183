import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import TeamMember from './TeamMember';
import JoinUs from './JoinUs';
import { Fade } from 'react-awesome-reveal';

import * as styles from './team.module.scss';

const Team = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query TeamMembers {
      allStrapiTeamMember {
        nodes {
          firstName
          lastName
          position
          locale
          order
          path
          content {
            type
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            markdown {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250, height: 250, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const sortAsc = (a, b) => a.order - b.order;
  const teamMembers = data.allStrapiTeamMember.nodes
    .filter((node) => node.locale === intl.locale)
    .sort(sortAsc);

  const navigateToTeamMemberDetails = (member) => {
    member.content.map((contentBlock) =>
      contentBlock.markdown.data.childMarkdownRemark.html !== ''
        ? navigate(`/${member.path}`)
        : null
    );
  };

  return (
    <div className={styles.teamSection} id="team">
      <div className="text-center">
        <h2>{intl.formatMessage({ id: 'main.team.title' })}</h2>
        <hr className="rectangle" />
        <p className="mx-1 mb-2">{intl.formatMessage({ id: 'main.team.paragraph1' })}</p>

        <Container>
          <Row className="justify-content-center">
            {teamMembers.map((member, index) => (
              <Col
                xs={6}
                md={3}
                className="px-1"
                key={index}
                onClick={() => navigateToTeamMemberDetails(member)}
              >
                <Fade triggerOnce delay={index * 60}>
                  <TeamMember
                    key={index}
                    image={member.image}
                    member={member}
                    firstName={member.firstName}
                    lastName={member.lastName}
                    position={member.position}
                  />
                </Fade>
              </Col>
            ))}
            <Col xs={6} md={3} className="px-1">
              <Fade triggerOnce delay={(teamMembers.length + 1) * 100}>
                <JoinUs />
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Team;
