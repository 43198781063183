// extracted by mini-css-extract-plugin
export var activePage = "paginated-articles-module--activePage--1f307";
export var bannerPostItem = "paginated-articles-module--bannerPostItem--7b4ea";
export var containerFluid = "paginated-articles-module--containerFluid--ce5ac";
export var disabledButton = "paginated-articles-module--disabledButton--dc17a";
export var infoEntry = "paginated-articles-module--infoEntry--80901";
export var linkBtn = "paginated-articles-module--linkBtn--06844";
export var nextButton = "paginated-articles-module--nextButton--d0f83";
export var pageTag = "paginated-articles-module--pageTag--92d2e";
export var paginatorContainer = "paginated-articles-module--paginatorContainer--c34ae";
export var postContent = "paginated-articles-module--postContent--9864c";
export var postImage = "paginated-articles-module--postImage--f85c3";
export var previousButton = "paginated-articles-module--previousButton--1478c";