/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/600.css';
import '@fontsource/red-hat-display/800.css';
import '@fontsource/red-hat-display/900.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import '@fontsource/red-hat-display/latin-ext-400.css';
import '@fontsource/red-hat-display/latin-ext-600.css';
import '@fontsource/red-hat-display/latin-ext-800.css';
import '@fontsource/red-hat-display/latin-ext-900.css';
import '@fontsource/roboto/latin-ext-300.css';
import '@fontsource/roboto/latin-ext-400.css';
import '@fontsource/roboto/latin-ext-700.css';
import './src/styles/style.scss';
