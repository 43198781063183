import React from 'react';
import c from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';

import items from './items';
import FooterColumn from './FooterColumn';

import * as styles from './footer.module.scss';

const Footer = () => {
  const intl = useIntl();

  return (
    <Container as={'footer'} fluid className={styles.footer}>
      <Row>
        <Container>
          <Row>
            <Col>
              <StaticImage
                height={28}
                src="../../images/brand/logo_white.png"
                alt="Indent Studio logo"
              />
              <span className="logo">
                &nbsp;Indent
                <strong> Studio</strong>
              </span>
              <span className={c(styles.slogan, 'pt-1 pl-lg-3 d-block d-lg-inline')}>
                {intl.formatMessage({ id: 'main.footer.slogan' })}
              </span>
            </Col>
          </Row>
          <Row className={styles.blocks}>
            {items.map((footerItem, index) => (
              <FooterColumn title={footerItem.title} items={footerItem.items} key={index} />
            ))}
          </Row>
          <Row>
            <Col>
              <hr className={c(styles.hr, 'mt-0')} />
            </Col>
          </Row>
          <Row className={c(styles.rights, 'text-white ')}>
            <Col>
              <span>&copy; 2020 - {new Date().getFullYear()} Indent Studio</span>{' '}
              <span className="float-md-right">
                {intl.formatMessage({ id: 'main.footer.rights' })}
              </span>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Footer;
