import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import { Nav } from 'react-bootstrap';

const FooterLink = ({ ...props }) => {
  const { children, to, className } = props;

  if (to.slice(0, 4) === 'http') {
    return (
      <Nav.Link className={className} target="_blank" href={to}>
        {children}
      </Nav.Link>
    );
  }

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
};

export default FooterLink;
