import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import c from 'classnames';
import * as styles from '../technologies.module.scss';

const TechnologyWithImage = ({ ...props }) => {
  const { imageName, technologyName } = props;

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          absolutePath: { regex: "/images/technologies/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 36, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const imageData = data.allFile.edges.filter((edge) => edge.node.name === imageName)[0];

  return (
    <div className={c(styles.devIconContainer, 'd-flex align-items-center py-1')}>
      <GatsbyImage
        image={getImage(imageData.node.childImageSharp.gatsbyImageData)}
        placeholder="blurred"
        alt={technologyName}
        height={36}
      />
      &nbsp;&nbsp;
      {technologyName}
    </div>
  );
};

export default TechnologyWithImage;
