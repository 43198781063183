import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Bounce } from 'react-awesome-reveal';
import { StaticImage } from 'gatsby-plugin-image';
import c from 'classnames';

import * as styles from '../allcomponents.module.scss';

const CuteCat = ({ ...props }) => {
  const { content } = props;

  return (
    <Container className={c(styles.staticContainer, 'py-2')}>
      <Row className="d-none d-sm-none d-md-flex overflow-hidden">
        <StaticImage
          src={'../../../images/static/development.png'}
          className={styles.development}
          alt="dev background"
          placeholder="blurred"
          height={400}
        />
        <Col xs={3} className={styles.greyArea}>
          <StaticImage
            src={'../../../images/static/greyarea.png'}
            alt="grey background"
            placeholder="blurred"
            width={432}
            height={549}
            layout="fixed"
          />
        </Col>
        <Col xs={3} className={styles.blueArea}>
          <StaticImage
            src={'../../../images/static/bluearea.png'}
            alt="blue background"
            placeholder="blurred"
            width={827}
            height={490}
            layout="fixed"
          />
        </Col>
        <Col xs={5} className="px-0 d-flex align-items-center">
          <Bounce triggerOnce>
            <p className={styles.blue} dangerouslySetInnerHTML={{ __html: content }} />
          </Bounce>
        </Col>
      </Row>

      <Row>
        <Col className="px-0 d-flex d-sm-flex d-md-none align-items-center">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
      </Row>
    </Container>
  );
};

export default CuteCat;
