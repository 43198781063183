import React from 'react';
import Layout from '../components/Layout';
import { useIntl } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../components/Seo';
import Hero from '../components/Hero';
import Reference from '../components/Reference';

const References = (props) => {
  const intl = useIntl();

  const generalSettings = useStaticQuery(graphql`
    query Site {
      allStrapiGeneralSetting {
        nodes {
          siteDescription
          siteTitle
          locale
          seo {
            metaDescription
            metaTitle
            keywords
          }
          socials {
            socialNetwork
            title
            description
          }
        }
      }
    }
  `);

  const seoData = generalSettings.allStrapiGeneralSetting.nodes.find(
    (s) => s.locale === intl.locale
  );

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={seoData.seo.metaTitle}
        description={seoData.seo.metaDescription}
        keywords={seoData.seo.keywords}
      />
      <Hero title={intl.formatMessage({ id: `references.title` })} subtitle={''} />
      <Reference />
    </Layout>
  );
};

export default References;
