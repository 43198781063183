import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Rectangle from '../../../images/static/rectangle.inline.svg';

const TwoColumns = () => {
  return (
    <Container>
      <Row className="mb-4">
        <Col md={{ span: 8, offset: 2 }} className="text-center">
          <h1>Will help to lorem ipsum set amet</h1>
          <Rectangle />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="px-3">
          <p>
            Lorem ipsum dolor sit amet, eros repudiandae quo ea, sale voluptua definitionem ei duo,
            eu labore feugait adipiscing est. Nam tritani impedit at. Id nec putant adipiscing
            vituperata, ex vix ipsum mazim impedit, vocent expetendis eloquentiam ei cum. In prompta
            iudicabit his, his nibh oratio argumentum eu, et nam illud novum veritus.
          </p>{' '}
          <p>
            Vide brute eu vim, agam animal pro cu. Et elitr quaeque nusquam pri, at eos mundi
            vulputate posidonium, populo definitionem has ea. Id nam ferri posse accusata. Omnium
            contentiones cum ad. Ex dolore volutpat delicatissimi mei, eos delicata convenire
            forensibus ea. Duo eripuit.
          </p>
        </Col>
        <Col sm={6} className="px-3">
          <p>
            Vix eu minim mollis persecuti, albucius appetere eleifend ex nam. Dicant labores
            partiendo ne vix, ea falli nulla nobis vim, cum id sonet percipit phaedrum. Eu pro quod
            viris signiferumque. Ne principes scriptorem definitiones duo.{' '}
          </p>
          <p>
            {' '}
            Sit erat definitionem ei. Ne doctus erroribus sed, est sale vidisse abhorreant ad, sit
            essent inermis ad. Vis et etiam libris fierent. Et alia saepe mea, est voluptaria
            efficiantur in, nec ea hinc fabulas dignissim. Vis et etiam libris fierent. Et alia
            saepe mea, est voluptaria efficiantur in, nec ea hinc fabulas dignissim.{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TwoColumns;
