import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
// import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Hero';
import StaticComponent from '../components/StaticComponents';
import Positions from '../components/Positions';
// import Price from '../components/Price';

const SimplePage = ({ data }) => {
  const intl = useIntl();
  const page = data.allStrapiPage.nodes.filter((node) => node.locale === intl.locale)[0];

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        keywords={page.seo.keywords}
      />
      <Hero title={page.title} subtitle={page.subtitle} />
      <div className="static-component">
        {page.content &&
          page.content.map(
            (contentBlock, index) =>
              contentBlock.markdown.data !== null && (
                <StaticComponent
                  key={index}
                  content={contentBlock.markdown.data.childMarkdownRemark.html}
                  type={contentBlock.type}
                  image={contentBlock.image}
                  path={page.path}
                />
              )
          )}
        {page.path === 'career' && <Positions />}
      </div>
      {/* oh jesus */}
      {/* {page.path === 'development-squads' && (
        <Container className="py-4">
          <Row>
            <Col className="text-center pb-2">
              <h2>{intl.formatMessage({ id: 'price.title' })}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#F3E0F2"
                packagePrice={20}
                packageTimeframe={intl.formatMessage({ id: 'price.hour' })}
                packageName={intl.formatMessage({
                  id: 'price.development_squads.medior_developer'
                })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.development_squads.features.support' }),
                  intl.formatMessage({ id: 'price.development_squads.features.trained' })
                ]}
              />
            </Col>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#F7F6FF"
                packagePrice={40}
                packageTimeframe={intl.formatMessage({ id: 'price.hour' })}
                packageName={intl.formatMessage({
                  id: 'price.development_squads.senior_developer'
                })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.development_squads.features.support' }),
                  intl.formatMessage({ id: 'price.development_squads.features.trained' }),
                  intl.formatMessage({ id: 'price.development_squads.features.lonewolf' })
                ]}
              />
            </Col>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#FFEAEA"
                packagePrice={50}
                packageTimeframe={intl.formatMessage({ id: 'price.hour' })}
                packageName={intl.formatMessage({
                  id: 'price.development_squads.architect_developer'
                })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.development_squads.features.support' }),
                  intl.formatMessage({ id: 'price.development_squads.features.trained' }),
                  intl.formatMessage({ id: 'price.development_squads.features.lonewolf' }),
                  intl.formatMessage({ id: 'price.development_squads.features.code_review' })
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="pt-4">
                * {intl.formatMessage({ id: 'price.project_management_not_included' })}{' '}
                {intl.formatMessage({ id: 'price.request_an_offer' })}
              </p>
            </Col>
          </Row>
        </Container>
      )}
      {page.path === 'product-development' && (
        <Container className="py-4">
          <Row>
            <Col className="text-center pb-2">
              <h2>{intl.formatMessage({ id: 'price.title' })}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#F3E0F2"
                packagePrice={'2000 - '}
                packageTimeframe={intl.formatMessage({ id: 'price.week' })}
                packageName={intl.formatMessage({ id: 'price.product_development.small_team' })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.product_development.features.1_2_developers' }),
                  intl.formatMessage({ id: 'price.product_development.features.support' }),
                  intl.formatMessage({ id: 'price.product_development.features.trained' })
                ]}
              />
            </Col>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#F7F6FF"
                packagePrice={'3500 - €5000'}
                packageTimeframe={intl.formatMessage({ id: 'price.week' })}
                packageName={intl.formatMessage({
                  id: 'price.product_development.small_product_development_team'
                })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.product_development.features.2_3_developers' }),
                  intl.formatMessage({ id: 'price.product_development.features.support' }),
                  intl.formatMessage({ id: 'price.product_development.features.trained' }),
                  intl.formatMessage({
                    id: 'price.product_development.features.designer'
                  }),
                  intl.formatMessage({
                    id: 'price.product_development.features.product_consultants'
                  })
                ]}
              />
            </Col>
            <Col className="pt-2" md={4}>
              <Price
                bgColor="#FFEAEA"
                packagePrice={'7500 -'}
                packageTimeframe={intl.formatMessage({ id: 'price.week' })}
                packageName={intl.formatMessage({
                  id: 'price.product_development.full_product_development_team'
                })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.product_development.features.4_5_developers' }),
                  intl.formatMessage({ id: 'price.product_development.features.support' }),
                  intl.formatMessage({ id: 'price.product_development.features.trained' }),
                  intl.formatMessage({
                    id: 'price.product_development.features.designer'
                  }),
                  intl.formatMessage({
                    id: 'price.product_development.features.product_consultants'
                  }),
                  intl.formatMessage({
                    id: 'price.product_development.features.project_management'
                  })
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="pt-4">* {intl.formatMessage({ id: 'price.request_an_offer' })}</p>
            </Col>
          </Row>
        </Container>
      )}
      {page.path === 'consulting' && (
        <Container className="py-4">
          <Row>
            <Col className="text-center pb-2">
              <h2>{intl.formatMessage({ id: 'price.title' })}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <Price
                bgColor="#fff4db"
                packagePrice={'800 - '}
                packageTimeframe={intl.formatMessage({ id: 'price.day' })}
                packageName={intl.formatMessage({ id: 'price.consultancy.consultant' })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.consultancy.design' }),
                  intl.formatMessage({ id: 'price.consultancy.tech_specification' }),
                  intl.formatMessage({ id: 'price.consultancy.dev_starter_kit' }),
                  intl.formatMessage({
                    id: 'price.consultancy.backlog'
                  }),
                  intl.formatMessage({
                    id: 'price.consultancy.project_code_review'
                  })
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="pt-4">* {intl.formatMessage({ id: 'price.request_an_offer' })}</p>
            </Col>
          </Row>
        </Container>
      )}
      {page.path === 'design' && (
        <Container className="py-4">
          <Row>
            <Col className="text-center pb-2">
              <h2>{intl.formatMessage({ id: 'price.title' })}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <Price
                bgColor="#fff4db"
                packagePrice={'40'}
                packageTimeframe={intl.formatMessage({ id: 'price.hour' })}
                packageName={intl.formatMessage({ id: 'price.design.designer' })}
                packageFeatures={[
                  intl.formatMessage({ id: 'price.design.mvp' }),
                  intl.formatMessage({ id: 'price.design.ux_review' }),
                  intl.formatMessage({ id: 'price.design.full_ui_ux' })
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="pt-4">* {intl.formatMessage({ id: 'price.request_an_offer' })}</p>
            </Col>
          </Row>
        </Container>
      )} */}
    </Layout>
  );
};

export const SimplePageQuery = graphql`
  query SimplePage($pagePath: String) {
    allStrapiPage(filter: { path: { eq: $pagePath } }) {
      nodes {
        path
        title
        subtitle
        locale
        content {
          type
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        seo {
          metaDescription
          metaTitle
          keywords
        }
      }
    }
  }
`;

export default SimplePage;
