import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Bounce } from 'react-awesome-reveal';

import * as styles from '../allcomponents.module.scss';

const ImageOnLeft = ({ ...props }) => {
  const { content, image } = props;

  return (
    <Container className="py-2">
      <Container fluid>
        <Row className={c(styles.bgLightPurple, 'd-flex align-items-center')}>
          <Col md={8}>
            {image && <GatsbyImage image={getImage(image.localFile)} alt="Indent Studio" />}
            {!image && (
              <StaticImage
                src="../../../images/static/development.png"
                alt="dev"
                placeholder="blurred"
              />
            )}
          </Col>
          <Col md={4}>
            <Bounce triggerOnce>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Bounce>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ImageOnLeft;
