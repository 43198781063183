import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';

const NoPageFound = () => {
  const intl = useIntl();

  return (
    <Layout>
      <div className="text-center my-4">
        <h1>404</h1>
        <p>{intl.formatMessage({ id: 'main.404.notfound' })}</p>
      </div>
    </Layout>
  );
};

export default NoPageFound;
