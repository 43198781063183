import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import c from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import ReferenceItem from './ReferenceItem';
import * as styles from './reference.module.scss';

const Reference = () => {
  const data = useStaticQuery(graphql`
    query References {
      allStrapiReference {
        nodes {
          name
          description
          locale
          link
          hiddenCategory
          mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 215, placeholder: BLURRED)
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 108, placeholder: BLURRED)
              }
            }
          }
          tags {
            name
          }
        }
      }
    }
  `);
  const intl = useIntl();
  const references = data.allStrapiReference.nodes.filter((node) => node.locale === intl.locale);

  return (
    <Container className={styles.reference}>
      <Row className="text-center">
        <Col>
          <h2>{intl.formatMessage({ id: 'main.reference.title' })}</h2>
          <hr className="rectangle" />
          <p>{intl.formatMessage({ id: 'main.reference.subtitle' })}</p>
        </Col>
      </Row>
      <Tabs defaultActiveKey="all" id="references-tab" className="mb-3" variant="pills">
        <Tab eventKey="all" title="All">
          <Row>
            {references.map((reference, index) => (
              <Col sm={12} md={6} lg={4} key={index}>
                <Fade triggerOnce delay={index * 50}>
                  <ReferenceItem
                    mainImage={reference.mainImage}
                    logo={reference.logo}
                    link={reference.link}
                    name={reference.name}
                    description={reference.description}
                    badges={reference.tags}
                    key={index}
                  />
                </Fade>
              </Col>
            ))}
          </Row>
        </Tab>
        <Tab eventKey="web" title="Web">
          <Row>
            {references
              .filter((reference) => reference.hiddenCategory.indexOf('web') > -1)
              .map((reference, index) => (
                <Col sm={12} md={6} lg={4} key={index}>
                  <Fade triggerOnce delay={index * 50}>
                    <ReferenceItem
                      mainImage={reference.mainImage}
                      logo={reference.logo}
                      link={reference.link}
                      name={reference.name}
                      description={reference.description}
                      badges={reference.tags}
                      key={index}
                    />
                  </Fade>
                </Col>
              ))}
          </Row>
        </Tab>
        <Tab eventKey="design" title="Design">
          <Row>
            {references
              .filter((reference) => reference.hiddenCategory.indexOf('design') > -1)
              .map((reference, index) => (
                <Col sm={12} md={6} lg={4} key={index}>
                  <Fade triggerOnce delay={index * 50}>
                    <ReferenceItem
                      mainImage={reference.mainImage}
                      logo={reference.logo}
                      link={reference.link}
                      name={reference.name}
                      description={reference.description}
                      badges={reference.tags}
                      key={index}
                    />
                  </Fade>
                </Col>
              ))}
          </Row>
        </Tab>
        <Tab eventKey="mobile" title="Mobile">
          <Row>
            {references
              .filter((reference) => reference.hiddenCategory.indexOf('mobile') > -1)
              .map((reference, index) => (
                <Col sm={12} md={6} lg={4} key={index}>
                  <Fade triggerOnce delay={index * 50}>
                    <ReferenceItem
                      mainImage={reference.mainImage}
                      logo={reference.logo}
                      link={reference.link}
                      name={reference.name}
                      description={reference.description}
                      badges={reference.tags}
                      key={index}
                    />
                  </Fade>
                </Col>
              ))}
          </Row>
        </Tab>
      </Tabs>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Link to="/contact" className={c('btn btn-primary', styles.btn)}>
            {intl.formatMessage({ id: 'main.reference.button' })}
          </Link>
        </Col>
      </Row>
      <StaticImage
        src={'../../images/reference/pattern_mobile_reference.png'}
        className={c(styles.dotsMobile, 'd-md-none')}
        alt="mobile dots background"
        placeholder="blurred"
      />
      <StaticImage
        src={'../../images/reference/pattern_reference.png'}
        className={c(styles.dots, 'd-none d-md-block bg-red')}
        alt="dots"
        placeholder="blurred"
      />
    </Container>
  );
};

export default Reference;
