import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Hero title={intl.formatMessage({ id: 'main.header.contact' })} subtitle={' '} />
      <ContactForm />
    </Layout>
  );
};

export default Contact;
