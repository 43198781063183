// extracted by mini-css-extract-plugin
export var blocks = "footer-module--blocks--7c821";
export var close = "footer-module--close--f1695";
export var closeRotation = "footer-module--closeRotation--a2814";
export var footer = "footer-module--footer--42660";
export var footerItem = "footer-module--footerItem--f4f0c";
export var footerTitle = "footer-module--footerTitle--7e80e";
export var hr = "footer-module--hr--90b79";
export var iconFoot = "footer-module--iconFoot--864d6";
export var iconHeart = "footer-module--iconHeart--08df9";
export var open = "footer-module--open--edf9d";
export var openRotation = "footer-module--openRotation--fd0ac";
export var rights = "footer-module--rights--05d1d";
export var slogan = "footer-module--slogan--b5723";
export var socialIcon = "footer-module--socialIcon--85bff";