import React from 'react';
import { Container, Col } from 'react-bootstrap';
import c from 'classnames';

import * as styles from '../allcomponents.module.scss';

const GreyBackground = ({ ...props }) => {
  const { content } = props;

  return (
    <Container fluid className={c(styles.container, 'py-md-5 py-3')}>
      <Col md={{ span: 6, offset: 3 }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Col>
    </Container>
  );
};

export default GreyBackground;
