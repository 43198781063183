import React, { useState } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { changeLocale, useIntl, Link } from 'gatsby-plugin-intl';
import c from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './navbar.module.scss';
import CloseIcon from '../../images/icons/close.inline.svg';
import ArrowIcon from '../../images/icons/arrow.inline.svg';
import GitHubIcon from '../../images/icons/github.inline.svg';
import HamburgerIcon from '../../images/icons/hamburger.inline.svg';
import LinkedInIcon from '../../images/icons/linkedin.inline.svg';
import InstagramIcon from '../../images/icons/instagram.inline.svg';
import FacebookIcon from '../../images/icons/facebook.inline.svg';

const CustomNavbar = () => {
  const intl = useIntl();

  let [isCollapsed, setIsCollapsed] = useState(false);
  let handleClick = () => setIsCollapsed(!isCollapsed);

  return (
    <Navbar
      className={c('py-lg-0', styles.navbar)}
      sticky="top"
      collapseOnSelect
      expand="lg"
      bg="light"
    >
      <Navbar.Brand className="mr-0">
        <Link to="/">
          <StaticImage
            height={30}
            src="../../images/brand/logo.png"
            alt="Indent Studio logo"
            className={styles.logo}
          />
          &nbsp;Indent
          <strong> Studio</strong>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        className={styles.toggle}
        aria-controls="responsive-navbar-nav"
        onClick={handleClick}
      >
        {isCollapsed ? <CloseIcon /> : <HamburgerIcon />}
      </Navbar.Toggle>

      <Navbar.Collapse
        id="responsive-navbar-nav"
        className={c('bg-light justify-content-center', styles.navbarCollapse)}
      >
        <Nav className={c('mx-auto d-flex align-items-start', styles.navbarNav)}>
          <Nav.Item className={c('pr-4', styles.navItem)}>
            <ArrowIcon className={c('d-lg-none', styles.arrow)} />
            <Link to={`/about-us`}>{intl.formatMessage({ id: 'main.header.about_us' })}</Link>
          </Nav.Item>
          <Nav.Item className={c('pr-4', styles.navItem)}>
            <ArrowIcon className={c('d-lg-none', styles.arrow)} />
            <Link to={`/#services`}>{intl.formatMessage({ id: 'main.header.services' })}</Link>
          </Nav.Item>
          <Nav.Item className={c('pr-4', styles.navItem)}>
            <ArrowIcon className={c('d-lg-none', styles.arrow)} />
            <Link to={`/#team`}>{intl.formatMessage({ id: 'main.header.team' })}</Link>
          </Nav.Item>
          <Nav.Item className={c('pr-4', styles.navItem)}>
            <ArrowIcon className={c('d-lg-none', styles.arrow)} />
            <Link to={`/contact`}>{intl.formatMessage({ id: 'main.header.contact' })}</Link>
          </Nav.Item>
          <Nav.Item className={c('pr-4', styles.navItem)}>
            <ArrowIcon className={c('d-lg-none', styles.arrow)} />
            <Link to={`/career`}>{intl.formatMessage({ id: 'main.header.career' })}</Link>
          </Nav.Item>

          <Nav.Item className="d-flex d-lg-none mx-auto py-4">
            <div className="px-1 d-flex">
              <GitHubIcon className={styles.navIcon} />
            </div>
            <div className="px-1 d-flex">
              <LinkedInIcon className={styles.navIcon} />
            </div>
            <div className="px-1 d-flex">
              <InstagramIcon className={styles.navIcon} />
            </div>
            <div className="px-1 d-flex">
              <FacebookIcon className={styles.navIcon} />
            </div>
          </Nav.Item>
          <Nav.Item className="d-flex d-lg-none mx-auto text-center py-2">
            <Button
              variant="link"
              onClick={() => {
                intl.locale === 'hu' ? changeLocale('en') : changeLocale('hu');
              }}
            >
              {intl.locale === 'hu' ? 'English' : 'Magyar'}
            </Button>
          </Nav.Item>
        </Nav>
        <Nav.Item className="d-none d-lg-block text-center py-2">
          <Button
            className="ml-auto"
            variant="link"
            onClick={() => {
              intl.locale === 'hu' ? changeLocale('en') : changeLocale('hu');
            }}
          >
            {intl.locale === 'hu' ? 'English' : 'Magyar'}
          </Button>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
