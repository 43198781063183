import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useIntl } from 'gatsby-plugin-intl';
import c from 'classnames';

import * as styles from '../TeamMember/team_member.module.scss';
import * as joinStyles from './join_us.module.scss';
import ChatIcon from '../../../images/icons/chat.inline.svg';

const JoinUs = () => {
  const intl = useIntl();

  return (
    <Link to="/career/">
      <div className={c(styles.teamMember, 'mb-md-4 mb-2')}>
        <div className={joinStyles.wrapper}>
          <div className={joinStyles.you}>
            <h1 className={joinStyles.youText}>{intl.formatMessage({ id: `main.team.you` })}</h1>
          </div>
        </div>

        <Container>
          <Row className="justify-content-between">
            <Col md={9} xs={9} className="pr-1">
              <h5 className=" pt-1  mb-0">{intl.formatMessage({ id: `main.team.random_name` })}</h5>
              <p className=" pl-0 mt-0">
                {intl.formatMessage({ id: `main.team.random_smart_person` })}
              </p>
            </Col>
            <Col md={2} xs={3} className="px-0">
              <ChatIcon className="mt-1" />
            </Col>
          </Row>
        </Container>
      </div>
    </Link>
  );
};

export default JoinUs;
